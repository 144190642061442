<style lang="scss"></style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Possessions')">
    <b-card-actions class="mb-0" no-actions no-body title="Volumetric Controls">
      <section class="m-2">
        <b-row class="mb-2">
          <b-col v-if="$can('Read', 'Locations')" cols="4">
            <label>Location</label>
            <location-search-select
              :selected="selectedLocation"
              @change="updateSelectedLocation"
            />
          </b-col>
        </b-row>
      </section>
      <section>
        <VolumetricControl
          v-if="selectedLocation"
          :location-id="selectedLocation.id"
          :owner-uid="this.uid"
        />
      </section>
    </b-card-actions>

    <b-card-actions class="mb-0" no-actions no-body title="Owner Possessions">
      <section>
        <PossessionsTable
          :default-filters="{ owner_id: this.uid }"
          :show-create-button="false"
          :show-owner-filter="false"
        />
      </section>
    </b-card-actions>
  </div>
</template>
<!--eslint-enable-->

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import PossessionsTable from "@/views/possessions/sections/PossessionTable.vue";
import VolumetricControl from "@/views/possessions/sections/VolumetricControl.vue";
import LocationSearchSelect from "@/components/input/PaginatedSearchSelect/LocationSearchSelect.vue";

export default {
  name: "OwnerDevices",
  components: {
    LocationSearchSelect,
    VolumetricControl,
    PossessionsTable,
    BCardActions,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedLocation: null,
    };
  },
  methods: {
    updateSelectedLocation(location) {
      this.selectedLocation = location;
    }
  },
};
</script>
