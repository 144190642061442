<!--eslint-disable-->
<template>
  <div class="users-page">
    <b-card class="mb-0" no-body>
      <!-- Filters -->
      <div class="m-2">
        <b-row
          v-if="$can('Create', 'Possessions') && showCreateButton"
          class="mb-2"
        >
          <b-col>
            <b-button
              variant="primary"
              @click="$bvModal.show('modal-add-possession')"
              >Create Possession
            </b-button>
          </b-col>
        </b-row>

        <!-- Filters 1 -->
        <div class="d-flex flex-wrap">
          <!-- Search -->
          <div v-if="showSearchFilter" class="flex-grow-1 mr-1 mb-1">
            <label>Search</label>
            <b-input-group>
              <b-form-input
                v-model="searchVal"
                class="d-inline-block"
                placeholder="Search possessions..."
                @keydown.enter="searchPossessions()"
              />
              <b-input-group-append>
                <b-button @click="searchPossessions()">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>

          <!-- Owner -->
          <div
            v-if="$can('Read', 'Locations') && showOwnerFilter"
            class="flex-grow-1 table-filter mr-1 mb-1"
          >
            <label>Owner</label>
            <owner-search-select
              :selected="selectedOwner"
              @change="updateSelectedOwner"
            />
          </div>

          <!-- Location -->
          <div
            v-if="$can('Read', 'Locations') && showLocationFilter"
            class="flex-grow-1 table-filter mr-1 mb-1"
          >
            <label>Location</label>
            <location-search-select
              :selected="selectedLocation"
              @change="updateSelectedLocation"
            />
          </div>

          <!-- Category -->
          <div
            v-if="$can('Read', 'PossessionCategories') && showCategoryFilters"
            class="flex-grow-1 table-filter mr-1 mb-1"
          >
            <label>Category</label>
            <possession-category-search-select
              :selected="selectedParentCategory"
              @change="updateSelectedParentCategory"
            />
          </div>

          <!-- Sub Category -->
          <div
            v-if="$can('Read', 'PossessionCategories') && showCategoryFilters"
            class="flex-grow-1 table-filter mr-1 mb-1"
          >
            <label>Sub Category</label>
            <possession-category-search-select
              :selected="selectedSubcategory"
              :parent="selectedParentCategory"
              @change="updateSelectedSubcategory"
            />
          </div>

          <!-- Is Risk -->
          <div v-if="showIsRiskFilter" class="flex-grow-1 table-filter mr-1">
            <label>Is Risk</label>
            <b-form-select
              v-model="filters.is_risk"
              class="mb-2"
              @change="searchPossessions"
            >
              <b-form-select-option value="">All</b-form-select-option>
              <b-form-select-option value="true">Yes</b-form-select-option>
              <b-form-select-option value="false">No</b-form-select-option>
            </b-form-select>
          </div>

          <!-- Is Prohibited -->
          <div
            v-if="showIsProhibitedFilter"
            class="flex-grow-1 table-filter mr-1"
          >
            <label>Is Prohibited</label>
            <b-form-select
              v-model="filters.is_prohibited"
              class="mb-2"
              @change="searchPossessions"
            >
              <b-form-select-option value="">All</b-form-select-option>
              <b-form-select-option value="true">Yes</b-form-select-option>
              <b-form-select-option value="false">No</b-form-select-option>
            </b-form-select>
          </div>
        </div>

        <div class="float-right">
          <b-button @click="clearFilters">Clear Filters</b-button>
        </div>
      </div>

      <b-table
        :current-page="currentPage"
        :fields="headers"
        :items="possessions"
        :per-page="0"
        class="data-table"
        hover
        responsive
      >
        <template #cell(image)="data">
          <b-img
            v-if="data.item.images && data.item.images[0]"
            :src="getImgUrl(data.item.images[0].file_location)"
            class="table-img"
            fluid
          />
          <span v-else>No Image</span>
        </template>
        <template #cell(title)="data">
          <router-link
            :to="{ name: 'possession-details', params: { id: data.item.id } }"
          >
            {{ data.item.title }}
          </router-link>
        </template>
        <template #cell(category)="data">
          <p v-if="data.item.category && data.item.subcategory">
            {{ data.item.category.name }}/{{ data.item.subcategory.name }}
          </p>
        </template>
        <template #cell(is_prohibited)="data">
          <span>{{ data.item.is_prohibited ? "Yes" : "No" }}</span>
        </template>
        <template #cell(is_risk)="data">
          <span>{{ data.item.is_risk ? "Yes" : "No" }}</span>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            class="m-0"
            no-caret
            right
            toggle-class="text-decoration-none"
            variant="link"
          >
            <template #button-content>
              <i class="fas fa-ellipsis-v" />
            </template>
            <b-dropdown-item-button
              v-if="$can('Update', 'Possessions')"
              class="full-nested-btn"
              @click="showModal(data.item, 'modal-update-possession')"
            >
              Update possession
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="$can('Delete', 'Possessions')"
              class="full-nested-btn"
              @click="showModal(data.item, 'modal-delete-possession')"
            >
              Delete possession
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row>
        <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
          <b-pagination
            v-model="currentPage"
            :per-page="filters.page_size"
            :total-rows="total_count"
            class="my-0"
            @change="getMorePossessions"
          />
        </b-col>
        <b-col v-if="total_count === 0" class="p-2 pl-3">
          No Possessions found
        </b-col>
      </b-row>
    </b-card>

    <!-- Modals -->
    <b-modal id="modal-add-possession" hide-footer title="Add new possession">
      <add-possession @close="closeModals()" @refresh="refreshPossessions" />
    </b-modal>

    <b-modal id="modal-update-possession" hide-footer title="Update possession">
      <update-possession
        v-if="targetPossession"
        :target-possession="targetPossession"
        @close="closeModals()"
        @refresh="refreshPossessions()"
      />
    </b-modal>

    <b-modal id="modal-delete-possession" hide-footer title="Delete possession">
      <delete-modal
        title="Are you sure you wish to delete this possession"
        @close="closeModals()"
        @delete="deletePossession(targetPossession)"
      />
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import helperService from "@/services/HelperService";
import CountryService from "@/services/CountryService";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import PossessionsService from "@/services/PossessionsService";
import AddPossession from "@/views/possessions/modals/AddPossession.vue";
import UpdatePossession from "@/views/owners/sections/UpdatePossession.vue";
import OwnerSearchSelect from "@/components/input/PaginatedSearchSelect/OwnerSearchSelect.vue";
import LocationSearchSelect from "@/components/input/PaginatedSearchSelect/LocationSearchSelect.vue";
import PossessionCategorySearchSelect from "@/components/input/PaginatedSearchSelect/PossessionCategorySearchSelect.vue";

export default {
  name: "PossessionsTable",
  components: {
    PossessionCategorySearchSelect,
    OwnerSearchSelect,
    LocationSearchSelect,
    AddPossession,
    DeleteModal,
    UpdatePossession,
  },
  props: {
    defaultFilters: {
      type: Object,
      required: true,
      default: () => {},
    },
    showSearchFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showOwnerFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLocationFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showIsRiskFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showIsProhibitedFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCategoryFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      searchVal: "",
      filters: {
        page: this.$props.defaultFilters.page | 0,
        page_size: this.$props.defaultFilters.page_size | 25,
        search: !!this.$props.defaultFilters.search
          ? this.$props.defaultFilters.search
          : "",
        category_id: this.$props.defaultFilters.category_id | null,
        sub_category_id: this.$props.defaultFilters.sub_category_id | null,
        location_id: this.$props.defaultFilters.location_id | null,
        is_prohibited: !!this.$props.defaultFilters.is_prohibited
          ? this.$props.defaultFilters.is_prohibited
          : "",
        is_risk: !!this.$props.defaultFilters.is_risk
          ? this.$props.defaultFilters.is_risk
          : "",
        owner_id: !!this.$props.defaultFilters.owner_id
          ? this.$props.defaultFilters.owner_id
          : "",
      },
      possessions: [],
      targetPossession: null,
      currentPage: 1,
      total_count: 0,
      headers: [
        {
          key: "image",
          label: "Image",
        },
        {
          key: "title",
          label: "Title",
        },
        {
          key: "category",
          label: "Category",
        },
        {
          key: "owner.identifier",
          label: "Owner",
        },
        {
          key: "location.name",
          label: "Location",
        },
        {
          key: "is_prohibited",
          label: "Prohibited",
        },
        {
          key: "is_risk",
          label: "Risk",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      selectedOwner: null,
      selectedLocation: null,
      selectedParentCategory: null,
      selectedSubcategory: null,
    };
  },
  mounted() {
    this.getPossessions();
  },
  methods: {
    clearFilters() {
      this.currentPage = 1;
      this.searchVal = "";
      this.filters = {
        page: this.defaultFilters.page || 0,
        page_size: this.defaultFilters.page_size || 25,
        search: this.defaultFilters.search || "",
        category_id: this.defaultFilters.category_id || null,
        sub_category_id: this.defaultFilters.sub_category_id || null,
        location_id: this.defaultFilters.location_id || null,
        is_prohibited: this.defaultFilters.is_prohibited || "",
        is_risk: this.defaultFilters.is_risk || "",
        owner_id: this.defaultFilters.owner_id || "",
      };

      this.selectedOwner = null;
      this.selectedLocation = null;
      this.selectedParentCategory = null;
      this.selectedSubcategory = null;
      this.getMorePossessions(1)
    },
    refreshPossessions() {
      this.closeModals();
      this.searchPossessions();
    },
    searchPossessions() {
      this.filters.search = this.searchVal;
      this.currentPage = 1;
      this.filters.page = 0;
      this.getPossessions();
    },
    getMorePossessions(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getPossessions();
    },
    async getPossessions() {
      try {
        const res = await PossessionsService.listPossessions(this.filters);
        this.possessions = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
        this.loading = false;
      } catch (err) {
        helperService.showNotfyErr(
          err,
          "Could not get possessions, please refresh and try again"
        );
      }
    },
    showModal(target, modalName) {
      this.targetPossession = target;
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide("modal-add-possession");
      this.$bvModal.hide("modal-delete-possession");
      this.$bvModal.hide("modal-update-possession");
    },
    async deletePossession(possession) {
      try {
        await PossessionsService.delete(possession.id);
        this.refreshPossessions();
        this.$toast.success(`${possession.title} Deleted`, {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        helperService.showNotfyErr(
          err,
          "Could not delete possession, please refresh and try again"
        );
      }
    },
    getImgUrl(fileLocation) {
      return `${CountryService.getApiUrl()}/files/${fileLocation}`;
    },
    updateSelectedOwner(owner) {
      this.selectedOwner = owner;
      if (!this.selectedOwner) {
        this.filters.owner_id = null;
      } else {
        this.filters.owner_id = this.selectedOwner.uid;
      }
      this.getMorePossessions(1);
    },
    updateSelectedLocation(location) {
      this.selectedLocation = location;
      if (!this.selectedLocation) {
        this.filters.location_id = null;
      } else {
        this.filters.location_id = this.selectedLocation.id;
      }
      this.getMorePossessions(1);
    },
    updateSelectedParentCategory(category) {
      if (this.selectedParentCategory !== category) {
        this.selectedSubcategory = null;
        this.filters.sub_category_id = null;
      }
      this.selectedParentCategory = category;
      if (!this.selectedParentCategory) {
        this.filters.category_id = null;
      } else {
        this.filters.category_id = this.selectedParentCategory.id;
      }
      this.getMorePossessions(1);
    },
    updateSelectedSubcategory(category) {
      this.selectedSubcategory = category;
      if (!this.selectedSubcategory) {
        this.filters.sub_category_id = null;
      } else {
        this.filters.sub_category_id = this.selectedSubcategory.id;
      }
      this.getMorePossessions(1);
    }
  },
};
</script>

<style lang="scss">
.autocomplete-input {
  border-radius: 0.25rem;
  background-color: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  background-image: none;
  padding: 0.4rem 12px;
  font-size: 14px;
}

.autocomplete-result {
  border-radius: 0.25rem;
  background-image: none;
  padding: 12px 12px 12px 12px;
  font-size: 14px;
}

.table-img {
  max-width: 100px;
  max-height: 60px;
}

.table-filter {
  min-width: 250px;
  max-width: 250px;
}
</style>
