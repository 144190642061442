<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(addPossession)">
        <section class="mb-2">
          <div>
            <label>Title *</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true, min: 2 }"
              name="Title"
            >
              <b-form-input
                v-model="possession.title"
                :state="getValidationState(validationContext)"
                class="mb-1"
                placeholder="Enter title"
              />

              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Description</label>
            <b-form-textarea
              v-model="possession.description"
              placeholder="Enter description"
            />
          </div>

          <div class="mb-1">
            <label>Colour</label>
            <b-form-input
              v-model="possession.colour"
              placeholder="Enter colour"
            />
          </div>

          <div class="mb-1">
            <label>Origin</label>
            <b-form-input
              v-model="possession.origin"
              placeholder="Enter origin"
            />
          </div>

          <div class="mb-1">
            <label>Cost</label>
            <b-form-input
              v-model="possession.cost_of_item"
              type="number"
              step="0.01"
              placeholder="Enter cost of item"
            />
          </div>

          <div class="mb-1">
            <label>Is Prohibited</label>
            <b-form-select v-model="possession.is_prohibited">
              <b-form-select-option selected value="false"
                >No</b-form-select-option
              >
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Is Risk</label>
            <b-form-select v-model="possession.is_risk">
              <b-form-select-option selected value="false"
                >No</b-form-select-option
              >
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Owner</label>
            <owner-search-select
              :selected="selectedOwner"
              @change="updateSelectedOwner"
            />
          </div>

          <div class="mb-2">
            <label>Category *</label>
            <possession-category-search-select
              :selected="selectedCategory"
              @change="updateSelectedCategory"
            />
          </div>

          <div class="mb-1">
            <label>Sub Category *</label>
            <possession-category-search-select
              :selected="selectedSubcategory"
              :parent="selectedCategory"
              @change="updateSelectedSubcategory"
            />
          </div>

          <div class="mb-1">
            <label>Location *</label>
            <location-search-select
              :selected="selectedLocation"
              @change="updateSelectedLocation"
            />
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button :disabled="!valid()" type="submit" variant="primary"
            >Add new possession</b-button
          >
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import PossessionsService from "@/services/PossessionsService";
import LocationSearchSelect from "@/components/input/PaginatedSearchSelect/LocationSearchSelect.vue";
import OwnerSearchSelect from "@/components/input/PaginatedSearchSelect/OwnerSearchSelect.vue";
import PossessionCategorySearchSelect from "@/components/input/PaginatedSearchSelect/PossessionCategorySearchSelect.vue";

export default {
  components: {
    PossessionCategorySearchSelect,
    OwnerSearchSelect,
    LocationSearchSelect,
  },
  props: {
    parentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedOwner: null,
      selectedLocation: null,
      selectedCategory: null,
      selectedSubcategory: null,
      possession: {
        title: "",
        description: "",
        colour: "",
        origin: "",
        is_prohibited: false,
        is_risk: false,
        date_of_purchase: null,
        cost_of_item: null,
        item_category_id: null,
        item_sub_category_id: null,
        location_id: null,
        owner_uid: null,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    valid() {
      if (this.possession.title.length <= 0) {
        return false;
      }
      if (!this.possession.item_category_id) {
        return false;
      }
      if (!this.possession.item_sub_category_id) {
        return false;
      }
      if (!this.possession.location_id) {
        return false;
      }

      return true;
    },
    async addPossession() {
      this.possession.title = this.possession.title.trim();
      this.possession.is_risk = this.possession.is_risk === "true";
      this.possession.is_prohibited = this.possession.is_prohibited === "true";

      // Convert cost of item to integer
      this.possession.cost_of_item = parseInt(this.possession.cost_of_item);

      PossessionsService.createPossession(this.possession)
        .then(() => {
          this.$toast.success(
            `Added possession ${this.possession.title} Successfully`,
            {
              toastClassName: ["toast-std", "success-toast"],
            }
          );

          this.$emit("refresh", true);
        })
        .catch(err => {
          const res = err.response;
          let errorText =
            "Could not add possession, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        }
      );
    },
    updateSelectedOwner(owner) {
      this.selectedOwner = owner;
      if (!this.selectedOwner) {
        this.possession.owner_uid = null;
      } else {
        this.possession.owner_uid = this.selectedOwner.uid;
      }
    },
    updateSelectedLocation(location) {
      this.selectedLocation = location;
      if (!this.selectedLocation) {
        this.possession.location_id = null;
      } else {
        this.possession.location_id = this.selectedLocation.id;
      }
    },
    updateSelectedCategory(category) {
      if (this.selectedCategory !== category) {
        this.selectedSubcategory = null;
        this.possession.item_sub_category_id = null;
      }
      this.selectedCategory = category;
      if (!this.selectedCategory) {
        this.possession.item_category_id = null;
      } else {
        this.possession.item_category_id = this.selectedCategory.id;
      }
    },
    updateSelectedSubcategory(category) {
      this.selectedSubcategory = category;
      if (!this.selectedSubcategory) {
        this.possession.item_sub_category_id = null;
      } else {
        this.possession.item_sub_category_id = this.selectedSubcategory.id;
      }
    }
  },
};
</script>
